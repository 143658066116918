import { routeLocaleMiddleware } from '@web/assets/i18n/helper'
import { SUPPORTED_LOCALES } from '@web/assets/i18n'

const ROUTE_NAMES = Object.freeze({
  SIGN_IN: 'auth-sign-in',
  FORGOT_PASSWORD: 'auth-password',
  SIGN_UP: 'auth-signup',
  HOME: 'Home',
  PROJECT_INDEX: 'project-index',
  PROJECT_DETAIL: 'project-detail',
  EVENT_INDEX: 'event-index',
  EVENT_DETAIL: 'event-detail',
  BLOG_INDEX: 'blog-index',
  BLOG_DETAIL: 'blog-detail',
  NEWSFEED_INDEX: 'newsfeed-index',
  NEWSFEED_DETAIL: 'newsfeed-detail',
  CALCULATOR: 'calculator',
  CV_SHOW: 'cv-show',
  CV_LANDING_PAGE: 'cv-landing-page',
  EXTRA: 'extra',
  KODERIA_ABOUT: 'koderia-about',
  ABOUT_US: 'about-us',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_MOBILE: 'newsletter-mobile',
  PROMO_INDEX: 'promo-index',
  QUESTIONAIRE: 'questionaire',
  THANK_YOU_QUESTIONAIRE: 'thank-you-questionaire',
  METHOD_QUESTIONAIRE: 'method-questionaire',
  PROGRAMMER_FORM_QUESTIONAIRE: 'programmer-form-questionaire',
  TESTER_FORM_QUESTIONAIRE: 'tester-form-questionaire',
  CV_QUESTIONAIRE: 'cv-questionaire',
  REFERRAL: 'referral',
  REFERRAL_FORM: 'referral-form',
  REFERRAL_FORM_PROJECT: 'referral-form-project',
  REFERRAL_APPROVAL: 'referral-approval',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  UNSUBSCRIBE_INDEX: 'unsubscribe-index',
  PROFILE: 'profile',
  PROFILE_SAVED: 'profile-saved',
  PROFILE_ADDED: 'profile-added',
  PROFILE_CV: 'profile-cv',
  FINISH_SIGNUP: 'finish-signup',
  WORK_COMPARISON: 'work-comparison',
  FRAME_MESTO_BRATISLAVA: 'frame-MestoBratislava',
  FRAME_PRACUJ_V_HERNOM_PRIEMYSLE: 'frame-PracujVHernomPriemysle',
  FRAME_NET_PROJEKTY: 'frame-NetProjekty',
  PAGE_404: 'page-404'
})

const routes = [
  {
    path: `/:locale(${ SUPPORTED_LOCALES.join('|') })?`,
    beforeEnter: routeLocaleMiddleware,
    component: {
      render(h) {
        return h('router-view')
      }
    },
    children: [
      {
        name: ROUTE_NAMES.SIGN_IN,
        path: 'sign-in',
        component: () => import(/* webpackChunkName: "auth-sign-in" */ '../auth/ka-sign-in-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_sign_in',
          requiresGuest: true
        }
      },
      {
        name: ROUTE_NAMES.FORGOT_PASSWORD,
        path: 'forgot-password',
        component: () => import(/* webpackChunkName: "auth-forgot-password" */ '../auth/ka-forgot-password-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_forgot_password',
          requiresGuest: true
        }
      },
      {
        name: ROUTE_NAMES.SIGN_UP,
        path: 'sign-up',
        component: () => import(/* webpackChunkName: "auth-sign-up" */ '../auth/ka-sign-up-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_create_account',
          requiresGuest: true
        }
      },
      {
        name: ROUTE_NAMES.HOME,
        path: '',
        component: () => import(/*webpackChunkName: "home"*/ '../home/pages/home-index'),
        meta: {
          title: null,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.PROJECT_INDEX,
        path: 'jobs',
        component: () => import(/* webpackChunkName: "project-index" */ '../projects/pages/project-index'),
        meta: {
          title: 'meta.work'
        }
      },
      {
        name: ROUTE_NAMES.PROJECT_DETAIL,
        path: 'jobs/:id',
        component: () => import(/* webpackChunkName: "project-detail" */ '../projects/pages/project-detail'),
        meta: {
          title: 'meta.work'
        }
      },
      {
        name: ROUTE_NAMES.EVENT_INDEX,
        path: 'events',
        component: () => import(/* webpackChunkName: "event-index" */ '../events/pages/event-index'),
        meta: {
          title: 'meta.events',
          appBarStyle: 'solid',
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.EVENT_DETAIL,
        path: 'events/:id',
        component: () => import(/* webpackChunkName: "event-detail" */ '../events/pages/event-detail'),
        meta: {
          title: 'meta.events',
          appBarStyle: 'transparent-light',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.BLOG_INDEX,
        path: 'blog',
        component: () => import(/* webpackChunkName: "blog-index" */ '../blog/pages/blog-index'),
        meta: {
          title: 'meta.blog',
          appBarStyle: 'transparent-light',
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },

      {
        name: ROUTE_NAMES.BLOG_DETAIL,
        path: 'blog/:slug',
        component: () => import(/* webpackChunkName: "blog-detail" */ '../blog/pages/blog-detail'),
        meta: {
          title: 'meta.blog',
          appBarStyle: 'solid',
          layout: 'default'
        }
      },
      {
        name: ROUTE_NAMES.NEWSFEED_INDEX,
        path: 'newsfeed',
        component: () => import(/* webpackChunkName: "newsfeed-index" */ '../newsfeed/pages/newsfeed-index'),
        meta: {
          title: 'meta.newsfeed'
        }
      },
      {
        name: ROUTE_NAMES.NEWSFEED_DETAIL,
        path: 'newsfeed/:id',
        component: () => import(/* webpackChunkName: "newsfeed-detail" */ '../newsfeed/pages/newsfeed-detail'),
        meta: {
          title: 'meta.newsfeed'
        }
      },
      {
        name: ROUTE_NAMES.CALCULATOR,
        path: 'salary-calculator',
        component: () => import(/* webpackChunkName: "calculator" */ '../calculator/calculator-page.vue'),
        meta: {
          title: 'meta.calculator',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.CV_SHOW,
        path: 'cv/:id',
        component: () => import(/*webpackChunkName: "cv"*/ '../cv/ka-user-cv-show-page'),
        meta: {
          title: 'meta.resume',
          appBarStyle: 'transparent-dark',
          layout: 'cv'
        }
      },
      {
        name: ROUTE_NAMES.CV_LANDING_PAGE,
        path: 'cv',
        component: () => import(/*webpackChunkName: "cv-landing"*/ '../cv/pages/cv-landing'),
        meta: {
          title: 'meta.resume',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.EXTRA,
        path: 'extra',
        component: () => import(/* webpackChunkName: "extra" */ '../extra/extra-page'),
        meta: {
          title: 'meta.extra',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.KODERIA_ABOUT,
        path: 'koderia',
        component: () => import(/* webpackChunkName: "koderia-about" */ '../koderia-about/koderia-about-page'),
        meta: {
          title: 'meta.what_is_koderia',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.ABOUT_US,
        path: 'about-us',
        component: () => import(/* webpackChunkName: "about" */ '../about-us/about-us-page'),
        meta: {
          title: 'meta.about_us',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.NEWSLETTER,
        path: 'newsletter',
        component: () => import(/* webpackChunkName: "newsletter" */ '../newsletter/newsletter-page'),
        meta: {
          title: 'meta.newsletter',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.NEWSLETTER_MOBILE,
        path: 'newsletter-mobile',
        component: () => import(/* webpackChunkName: "newsletter-mobile" */ '../newsletter/newsletter-mobile-page'),
        meta: {
          title: 'meta.newsletter',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'default',
          layoutProps: {
            noFooter: true,
            noNavbar: true
          }
        }
      },
      {
        name: ROUTE_NAMES.PROMO_INDEX,
        path: 'new-projects',
        component: () => import(/* webpackChunkName: "promo-index" */ '../promo/pages/promo-index'),
        meta: {
          title: 'meta.promo',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.QUESTIONAIRE,
        path: 'adequate-salary',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/questionaire-profession-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.THANK_YOU_QUESTIONAIRE,
        path: 'adequate-salary/thank-you',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/thank-you-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary_thank_you',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.METHOD_QUESTIONAIRE,
        path: 'adequate-salary/:queryProfession',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/questionaire-method-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.TESTER_FORM_QUESTIONAIRE,
        path: 'adequate-salary/tester/form',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/tester-form-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.PROGRAMMER_FORM_QUESTIONAIRE,
        path: 'adequate-salary/:programmer/form',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/programmer-form-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.CV_QUESTIONAIRE,
        path: 'adequate-salary/:queryProfession/cv',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/cv-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.REFERRAL,
        path: 'referral',
        component: () => import(/* webpackChunkName: "referral-index" */ '../referral/referral-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.REFERRAL_FORM,
        path: 'referral/form',
        component: () => import(/* webpackChunkName: "referral-form" */ '../referral/referral-form-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.REFERRAL_FORM_PROJECT,
        path: 'referral/form/:id',
        component: () => import(/* webpackChunkName: "referral-form-id" */ '../referral/referral-form-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'transparent-light'
        }
      },
      {
        name: ROUTE_NAMES.REFERRAL_APPROVAL,
        path: 'referral/consent/:id',
        component: () => import(/* webpackChunkName: "referral-approval" */ '../referral/referral-approval-page'),
        meta: {
          title: 'meta.referral_consent',
          appBarStyle: 'transparent-dark'
        }
      },
      {
        name: ROUTE_NAMES.TERMS_AND_CONDITIONS,
        path: 'privacy-policy',
        component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../terms-and-conditions/terms-and-conditions-page.vue'),
        meta: {
          title: 'meta.privacy_policy',
          appBarStyle: 'solid'
        }
      },
      {
        name: ROUTE_NAMES.UNSUBSCRIBE_INDEX,
        path: 'unsubscribe',
        component: () => import(/* webpackChunkName: "unsubscribe-index" */ '../unsubscribe/pages/unsubscribe-index'),
        meta: {
          title: 'meta.unsubscribe'
        }
      },
      {
        name: ROUTE_NAMES.PROFILE,
        path: 'profile',
        component: () => import(/*webpackChunkName: "profile"*/ '../user/ka-user-profile-page'),
        meta: {
          title: 'meta.account',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.PROFILE_SAVED,
        path: 'profile/saved',
        component: () => import(/*webpackChunkName: "profile-saved"*/ '../user/ka-user-saved-page'),
        meta: {
          title: 'meta.account_saved',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.PROFILE_ADDED,
        path: 'profile/added',
        component: () => import(/*webpackChunkName: "profile-added"*/ '../user/ka-user-added-page'),
        meta: {
          title: 'meta.account_added',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.PROFILE_CV,
        path: 'profile/cv',
        component: () => import(/*webpackChunkName: "profile-my-cv"*/ '../user/ka-user-cv-page'),
        meta: {
          title: 'meta.my_resume',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: ROUTE_NAMES.FINISH_SIGNUP,
        path: 'sign-up/finishing',
        component: () => import(/*webpackChunkName: "finish-signup"*/ '../user/ka-user-finish-signup'),
        meta: {
          title: 'meta.registration_completion',
          requiresAuth: true
        }
      },
      {
        name: ROUTE_NAMES.WORK_COMPARISON,
        path: 'work-types-comparison',
        component: () => import(/* webpackChunkName: "work-comparison" */ '../work-comparison/work-comparison-page.vue'),
        meta: {
          title: 'meta.work_types_comparison',
          appBarStyle: 'solid',
          redirectIfNotDefaultLanguage: true
        }
      },
      {
        name: ROUTE_NAMES.FRAME_MESTO_BRATISLAVA,
        path: 'TownBratislava',
        component: () => import('../frames/frame-mesto-bratislava'),
        meta: {
          title: 'meta.frame_help_bratislava',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame'
        }
      },
      {
        name: ROUTE_NAMES.FRAME_PRACUJ_V_HERNOM_PRIEMYSLE,
        path: 'work-in-game-industry',
        component: () => import('../frames/frame-pracuj-v-hernom-priemysle'),
        meta: {
          title: 'meta.frame_game_industry',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame'
        }
      },
      {
        name: ROUTE_NAMES.FRAME_NET_PROJEKTY,
        path: 'net-projects',
        component: () => import('../frames/frame-net-projekty'),
        meta: {
          title: 'meta.frame_net_teams',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame'
        }
      },
      {
        name: ROUTE_NAMES.PAGE_404,
        path: '*',
        component: () => import(/*webpackChunkName: "404"*/ '../404/404page.vue'),
        meta: {
          title: 'meta.404'
        }
      }
    ]
  }
]

export { ROUTE_NAMES }
export default routes
