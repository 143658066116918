import store from '../store'
import firebase from 'firebase/app'
import 'firebase/functions'

const FORM_STATES = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

const initialState = {
  formState: FORM_STATES.IDLE
}

store.registerModule('EXTRA', {
  namespaced: true,
  state: initialState,
  actions: {
    SUBMIT_EXTRA(ctx, payload) {
      ctx.commit('SET_FORM_STATE', FORM_STATES.PENDING)

      const { technologies } = payload.project
      const technologyNames = technologies.map(tech => tech.name)

      const data = {
        ...payload.contact,
        project: {
          ...payload.project,
          technologies: technologyNames
        },
        type: 'extra-interest'
      }

      const firebaseProcessExtra = firebase.functions().httpsCallable('processFormExtra')
      firebaseProcessExtra(data)
        .then(() => void ctx.commit('SET_FORM_STATE', FORM_STATES.SUCCESS))
        .catch(() => void ctx.commit('SET_FORM_STATE', FORM_STATES.ERROR))
    },
    RESET_FORM_STATE(ctx) {
      ctx.commit('SET_FORM_STATE', FORM_STATES.IDLE)
    }
  },
  mutations: {
    SET_FORM_STATE(state, formState) {
      state.formState = formState
    }
  }
})
