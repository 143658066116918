import store from '../store'
import { fetchEnums } from '@web/api/common-api'
import { fetchCountriesPhones } from '@web/services'
import Vue from 'vue'
import i18n from '@web/assets/i18n'
import { cloneDeep } from 'lodash'

const state = {
  professions: [],
  expertises: [],
  expertiseTypes: [],
  languages: [],
  languageLevels: [],
  countriesPhones: [],
  utmSources: [],
  nonItRegistrationReasons: []
}

const getters = {
  // TODO: will be needed in the future for radar (maybe)
  getExpertises: (state) => state.expertises.map(exp => {
    const { id, ...rest } = exp

    return {
      expertiseId: id,
      ...rest
    }
  }),
  getExpertiseTypes: (state) => state.expertiseTypes,
  getProfessions: (state) => state.professions,
  getProfessionsWithoutOther: (state) => state.professions.filter(profession => profession.value !== 'OTHER'),
  getLanguages: (state) => state.languages,
  getLanguageLevels: (state) => state.languageLevels,
  getCountriesPhones: (state) => state.countriesPhones,
  getDefaultPhoneCountry: (state) => state.countriesPhones.find(cPc => cPc.code === 'SVK'),
  getUtmSources: (state) => state.utmSources,
  getNonItRegistrationReasons: (state) => state.nonItRegistrationReasons
}

const actions = {
  async fetchCountriesPhones({ commit }) {
    let response = []

    try {
      response = await fetchCountriesPhones()
    } catch {
      // just try again, should work (sometimes their API throws error)
      try {
        response = await fetchCountriesPhones()
      } catch {
        Vue.$toast.error(i18n.t('global.errors.phone_codes_error'), { duration: 10000 })
      }

    }

    commit('setCountriesPhones', response)
  },
  async fetchEnums({ commit }) {
    try {
      const {
        expertiseTypes,
        professions,
        languages,
        languageLevels,
        utmSources,
        nonItRegistrationReasons
      } = await fetchEnums()

      commit('setExpertiseTypes', expertiseTypes)
      commit('setProfessions', professions)
      commit('setLanguages', languages)
      commit('setLanguageLevels', languageLevels)
      commit('setUtmSources', utmSources)
      commit('setNonItRegistrationReasons', nonItRegistrationReasons)
    } catch (e) {
      console.error(e)
      
      Vue.$toast.error('global.errors.unexpected_error')
    }

  },
  loadEnums({ dispatch }) {
    dispatch('fetchEnums')
  }
}

const mutations = {
  setExpertiseTypes(state, payload) {
    state.expertiseTypes = payload
  },
  setProfessions(state, payload) {
    state.professions = payload
  },
  setLanguages(state, payload) {
    state.languages = payload
  },
  setLanguageLevels(state, payload) {
    state.languageLevels = payload
  },
  setCountriesPhones(state, payload) {
    state.countriesPhones = payload
  },
  setUtmSources(state, payload) {
    state.utmSources = payload
  },
  setNonItRegistrationReasons(state, payload) {
    state.nonItRegistrationReasons = payload
  }
}

store.registerModule('ENUMS', {
  namespaced: true,
  state: cloneDeep(state),
  getters,
  actions,
  mutations
})
