import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../lang/en'
import sk from '../lang/sk'

const LanguageLocales = {
  sk,
  en
}

export const DEFAULT_LOCALE = 'sk'
export const SUPPORTED_LOCALES = Object.keys(LanguageLocales)

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: 'en',
  messages: {
    sk,
    en
  }
})

export default i18n
