<template>
  <div class="tw-flex">
    <div v-if="isMobile" class="tw-flex tw-items-center lang-wrapper-mobile">
      <button
        v-for="lang in languages"
        :key="lang.value"
        :class="['lang-btn', { 'lang-btn-active': lang.value === currentLocale }]"
        @click="switchLocale(lang.value)"
      >
        {{ lang.textShort }}
      </button>
    </div>

    <k-language-switcher-menu
      v-else
      :selected-language="selectedLanguage"
      :languages="languages"
      @onLanguageSelect="switchLocale($event)"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import KLanguageSwitcherMenu from '@web/components/core/k-language-switcher-menu'
import { getDefaultLocale } from '@web/assets/i18n/helper'

export default {
  name: 'k-language-nav-switcher',
  components: { KLanguageSwitcherMenu },
  data() {
    return {
      languages: [
        {
          text: 'Slovenský',
          textShort: 'SK',
          value: getDefaultLocale()
        },
        {
          text: 'English',
          textShort: 'EN',
          value: 'en'
        }
      ]
    }
  },
  computed: {
    selectedLanguage() {
      const selectedLanguage = this.languages.find(language => language.value === this.currentLocale)

      return selectedLanguage?.text || 'Slovenský'
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  top: unset;
}

.lang-wrapper-mobile {
  column-gap: 30px;
}

.lang-btn {
  border: none;
  background-color: transparent;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}

.lang-btn-active {
  color: $koderia;
}
</style>
