const en = {
  global: {
    my_resume: 'My CV',
    resume: 'CV',
    ok: 'OK',
    or: 'or',
    and: 'and',
    step: 'Step',
    yes: 'Yes',
    no: 'No',
    employment: 'Employment',
    from: 'From',
    to: 'To',
    start: 'Start',
    end: 'End',
    new: 'New',
    more_info: 'More info',
    trade: 'Trade',
    experience: 'Experience',
    place: 'Place',
    suggested: 'Suggested',
    titles: {
      all_done: 'Done',
      fill_out_form: 'Fill out the form',
      sent: 'Sent',
      added: 'Added',
      saved: 'Saved',
      welcome: 'Your registration was successful! Welcome to Koderia'
    },
    actions: {
      create_resume: 'Create',
      save: 'Save',
      recommend: 'Recommend',
      share: 'Share',
      upload_file: 'UPLOAD FILE',
      send: 'Send',
      continue: 'Continue',
      back: 'Back',
      select: 'Select',
      delete: 'Delete',
      delete_account: 'Delete account',
      add_post: 'Add a post',
      open: 'OPEN',
      upload_files: 'UPLOAD FILES',
      IMPORT: 'IMPORT',
      add_more: 'Add more',
      add: 'Add',
      add_another: 'Add another',
      copy: 'Copy',
      complete: 'Complete',
      resend: 'Resend',
      show_less: 'Show less',
      unlock: 'Unlock',
      change_image: 'Change image',
      change_logo: 'Change logo',
      publish: 'Publish',
      back_to_home_page: 'Back to homepage',
      edit: 'Edit',
      download: 'Download',
      close: 'Close',
      register: 'Register',
      confirm: 'Confirm',
      more: 'More'
    },
    time: {
      monthly: 'Monthly | Monthly',
      annual: 'Annual',
      today: 'Today',
      year: 'Year',
      years: 'year | years | years',
      month: 'Month',
      months: 'month | months | months',
      several_weeks: 'in a few weeks',
      months_3: '3 months',
      months_6: '6 months',
      manday: 'manday',
      now: 'Now',
      later: 'Later',
      january: 'january',
      february: 'february',
      march: 'march',
      april: 'april',
      may: 'may',
      june: 'june',
      july: 'july',
      august: 'august',
      september: 'september',
      october: 'october',
      november: 'november',
      december: 'december'
    },
    errors: {
      unknown: 'An error occurred. Please try again later.',
      already_exists: 'The email address is already in use by another account.',
      auth_expired_action_code: 'Token has expired.',
      auth_invalid_action_code: 'Invalid token.',
      auth_user_disabled: 'Account has been disabled.',
      auth_user_not_found: 'Invalid credentials.',
      auth_weak_password: 'The password is not strong enough.',
      auth_email_already_in_use: 'The email address is already in use by another account.',
      auth_invalid_email: 'Invalid email.',
      auth_operation_not_allowed: 'The operation is not allowed.',
      auth_account_exists_with_different_credential: 'Account already exists with different credentials.',
      auth_invalid_credential: 'Invalid credentials.',
      auth_wrong_password: 'Invalid password.',
      auth_invalid_phone_number: 'Invalid phone number.',
      auth_missing_phone_number: 'Missing phone number.',
      auth_cancelled_popup_request: 'The popup has been closed.',
      auth_popup_blocked: 'The popup has been blocked.',
      auth_popup_closed_by_user: 'The popup was closed before the operation was completed.',
      auth_user_token_expired: 'Token has expired.',
      auth_invalid_api_key: 'Invalid API key.',
      auth_network_request_failed: 'A network error has occurred. Please try again later.',
      auth_requires_recent_login: 'Recent login is required.',
      auth_too_many_requests: 'Too many requests in a short time.',
      auth_invalid_password: 'Invalid password.',
      auth_email_already_exists: 'The email address is already in use.',
      auth_phone_number_already_exists: 'Phone number already exists.',
      auth_insufficient_permission: 'You do not have sufficient permissions.',
      auth_internal: 'An error occurred. Please try again later.',
      permission_denied: 'You do not have sufficient permissions.',
      phone_codes_error: 'There was a problem fetching country phone codes. Please try again later.',
      unexpected_error: 'Unexpected error occurred. Please try again later.'
    },
    inputs: {
      examples: {
        name: 'John',
        email: 'john.dear@gmail.com',
        searchJob: 'Search for Frontend, Linux, SQL...'
      },
      firstname_surname: 'First Name and Last Name',
      email: 'E-mail',
      first_name: 'Name',
      last_name: 'Surname',
      password: 'Password',
      city: 'City',
      experiences: 'Experiences',
      phone: 'Phone',
      profession: 'Profession',
      technology: 'Technology',
      name: 'Name',
      company: 'Company',
      position: 'Position',
      title: 'Title',
      search_expertise: 'Search technology'
    },
    form_rules: {
      length_6: '6 or more characters',
      non_empty_field: 'Please fill in this field',
      valid_email: 'Please enter a valid email address',
      phone_number: 'The phone number must be in the format +421 9XX XXX XXX',
      min_3: 'At least 3',
      not_valid_email: 'Email must be a valid email address.',
      password_length: 'Password must contain at least 6 characters.',
      min_1_technology: 'Please enter at least 1 expertise.',
      event_image_required: 'Event image is required.',
      event_name_required: 'Event name is required.',
      event_name_min_3_characters: 'Event name must be at least 3 characters long.',
      event_datetime_manadatory: 'The date and time of the event is mandatory.',
      link_format: 'The link does not have the correct format (example: https://koderia.sk/podujatia).',
      event_reference_required: 'Event reference is required.',
      venue_required: 'The venue is mandatory.',
      event_description_required: 'Event description is required.',
      organizer_logo_required: 'Organizer logo is required.',
      organizer_name_required: 'Organizer name is required.',
      organizer_website_required: 'Organizer\'s website is mandatory.',
      organizer_description_required: 'The description of the organizer is mandatory.',
      organizer_description_long: 'Organizer description is too long.',
      event_end_before_start: 'The end of the event must not be before its start',
      required_field: 'Required field',
      first_name_filled: 'First name must be filled out.',
      last_name_filled: 'Last name must be filled out.',
      password_min_six_characters: 'Password must contain at least 6 characters.',
      accept_terms_conditions: 'You must accept the terms and conditions.'
    },
    form_errors: {
      check_entered_information: 'An error has occurred. Check the entered information.',
      upload_photo_max_size_5mb: 'Failed to upload photo, maximum size is 5 MB.',
      enter_name: 'Please enter your name.',
      enter_last_name: 'Please enter your last name.',
      enter_email: 'Please enter email',
      select_phone_code: 'Please select your country phone code.',
      enter_phone_number: 'Please enter your phone number.',
      valid_phone_number: 'Please enter valid phone number.',
      forgot_profession: 'Did you forget to fill your profession?',
      select_available_from_date: 'Please select available from date',
      no_data_available: 'No Data Available',
      select_utm_source: 'Please select how did you find out about us',
      select_non_it_registration_reason: 'Please select your reason to register'
    },
    deprecated: {
      agree_with_processing_personal_data_according_to_current_working: 'I agree with the processing of personal data according to the current wording',
      privacy_policy_small: 'privacy policy',
      confirm_that_koderia_contact_me_in_future: 'I confirm that Koderia may contact me in the future'
    },
    expertiseTypes: {
      'PROGRAMMING_LANGUAGE': 'Programming language',
      'FRAMEWORK': 'Framework',
      'DATABASE': 'Database',
      'LIBRARY': 'Library',
      'CLOUD': 'Cloud',
      'TOOLS': 'Tools',
      'METHODOLOGY': 'Methodology',
      'DEVOPS': 'Dev / Ops',
      'OTHER': 'Other'
    },
    expertiseLevel: {
      '0-1': 'Less than 1 year',
      1: '1 year',
      2: '2 years',
      3: '3 years',
      4: '4 years',
      '5-10': '5 – 10 years',
      '10+': 'More than 10 years'
    },
    utm_source: {
      referral: 'Referral'
    },
    non_it_registration_reasons: {
      student: 'I am studying IT and in the future I would like to become an IT specialist',
      no_contact: 'I\'m an IT person, but I don\'t want you to contact me with job offers',
      try_tools: 'I just want to try Koderia tools (e.g. Salary calculator, My CV)'
    }
  },
  ad: {
    not_interested_in_any_position: 'Were you not interested in any position? Tell us what the ideal project must meet and we will find it.',
    do_you_know_anyone_might_be_interested_in_this_position:
      'Do you know anyone who might be interested in this position? Recommend him / her and get a commission',
    every_day: 'every day',
    you_can_also_have_commission_from_this_position: 'You can also have a commission from this position',
    do_you_know_anyone_might_be_interested_in_position:
      'Do you know anyone who might be interested in these positions? Recommend him / her and get a commission'
  },
  auth: {
    titles: {
      create_account: 'Create an account',
      account: 'Account'
    },
    subTitles: {
      account_advantages: 'Enjoy all the benefits of Koderia account free of charge',
      verification: 'Account verification needed'
    },
    actions: {
      sign_up_simple: 'Sign up',
      sign_in_simple: 'Sign in',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      logout: 'Log out',
      create_new_account: 'Create a new one.',
      create_account: 'Create an account',
      forgot_password: 'Forgot password',
      sign_in_here: 'Sign in here.',
      sign_up_here: 'Sign up',
      verify: 'Send verification'
    },
    descriptions: {
      check_email: 'Check your e-mail box, as well as the spam folder. If you are unable to log in, write to us at',
      dont_have_account: 'Don\'t have an account yet?',
      email_associated_with_koderia: 'If the given email address is associated with an account on Koderia, we\'ll send it a link to reset the password.',
      check_confirmation_email: 'Please check your email box, where we sent you a confirmation email.',
      check_welcome_email: 'Please check your email box, where we sent you a welcome email.',
      already_have_account: 'Already have an account?',
      advantage_1: 'Access to valuable Koderia services',
      advantage_1_message: 'Favorite services Adequate salary, Salary calculator or Koderia Extra in one place',
      advantage_2: 'Simple response to IT projects',
      advantage_2_message: 'Thanks to the pre-filled forms, you can respond to Koderia IT projects and services with just one click',
      advantage_3: 'Personalized job offers',
      advantage_3_message: 'Job offers customized and sorted based on your specialization',
      advantage_4: 'Transparent cooperation',
      advantage_4_message: 'An overview of your data and used services, which you can return to or modify at any time',
      verification_dialog_message: 'Please verify your email address to access this section. We have sent a verification link to your registered email. Click the link in the email to proceed. If you haven\'t received the email, please check your spam folder or request a new verification email.',
      non_it_description: 'If you select the option that you are not from the IT field, we will not contact you or offer you work on projects. However, if you decide to change your focus in the future, you can always edit it in your profile. If you just chose the wrong profession or you don\'t know how to classify yourself, please select the OTHER option.'
    },
    inputs: {
      remember_login: 'Remember login'
    }
  },
  cookie: {
    description: 'The Koderia.sk website uses cookies to provide services and traffic analysis. By continuing to browse, you agree to their use.',
    more_information: 'More information.',
    button: 'I understand and agree'
  },
  navigation: {
    titles: {
      job_offer: 'Job Offers',
      legal_information: 'Legal information',
      social_network: 'Social',
      menu: 'Menu',
      next: 'Next'
    },
    home: 'Home',
    jobs: 'Jobs',
    carrier: 'Carrier',
    events: 'Events',
    blog: 'Blog',
    newsfeed: 'Newsfeed',
    dictionary: 'Dictionary',
    what_is_koderia: 'What is Koderia?',
    adequate_salary: 'Adequate salary',
    calculator: 'Salary Calculator',
    work_comparison: 'Work comparison',
    work_types_comparison: 'Comparison of work type',
    koderia_referral: 'Koderia Referral',
    koderia_extra: 'Koderia Extra',
    privacy_policy: 'Privacy Policy',
    resume: 'CV',
    create_resume: 'Create CV',
    terms_conditions: 'Terms and Conditions',
    koderia_dictionary: 'Koderia Dictionary',
    about_us: 'About Us',
    contact_us: 'Contact',
    policy: 'Policy',
    settings: 'Settings',
    create_new_koderia_biography: 'Create a new Koderia CV'
  },
  homepage: {
    home: 'Homepage',
    title:
      'Modern and clear CV. <span class=\'tw-text-green-light md:tw-text-white\'>For<br class=\'tw-hidden md:tw-inline\' /> everyone in IT. <br class=\'md:tw-hidden\' /> Free.</span>',
    subtitles: {
      jobs: 'The latest jobs',
      newsfeed: 'World newsfeed',
      events: 'Upcoming events',
      blogs: 'Latest blogs'
    },
    description:
      'Create responsive CVs in a few minutes, which<br class=\'tw-hidden md:tw-inline\' /> you can <b>edit</b>, <b>share</b> or <b>print</b> at any time.',
    actions: {
      jobs: 'All jobs',
      newsfeed: 'All posts',
      events: 'All events',
      blogs: 'All blogs',
      learn_more: 'Discover Koderia CV'
    }
  },
  project: {
    title: 'Is your colleague, friend, or acquaintance planning to change jobs?',
    description: 'Recommend him to one of our projects and in case of a successful agreement, earn a commission every day.',
    actions: {
      learn_more: 'Learn more',
      all: 'All'
    },
    detail: {
      status: {
        not_available: 'This project is already taken or is out of date.',
        new: 'New',
        remote: 'Remote',
        already_responded: 'You have already responded to this offer'
      },
      subtitles: {
        position: 'Position description',
        hr_need_info: 'Need more info?',
        interest_contacts: 'Contact information and CV',
        good_news: 'Good news',
        interest_upload_resume: 'Upload resume now?'
      },
      descriptions: {
        useful_for_you:
          'According to the info in your profile and CV, this job could be useful for you. Don\'t miss the opportunity to work on what you want.',
        create_resume: 'Create a Koderia CV and find out how your experience matches the requirements for this job offer.',
        team_contacts_you: 'Over the next few days, a member of our team will contact you and agree with you on the next steps.',
        modern_resume: 'A modern and clear CV for <br class="md:tw-hidden" /> everyone in IT.',
        hr_contact_directly: 'Contact our recruiters directly, who will be happy to tell you more.',
        interest_team_contacts_you: 'Thank you for your interest, we will contact you soon.',
        interest_recommended_upload: 'We recommend uploading now, we will need it.'
      },
      actions: {
        respond_to_offer: 'Apply for job',
        all_jobs: 'All jobs'
      },
      salary: 'Salary',
      location: 'Location',
      views: 'view | views | views',
      candidates: 'candidate | candidates | candidates',
      requirements: 'Requirements',
      employer_benefits: 'Employer benefits',
      project_duration: 'Project duration',
      duration_extension: 'with the possibility of extension',
      start_date: 'Start date',
      type: 'Type',
      tpp: 'TPP',
      contract: 'Contract',
      project_language: 'Language on the project',
      similar_job: 'Similar Job',
      free: 'Free',
      salary_per_contract: 'Salary per contract',
      salary_per_tpp: 'Salary on TPP',
      top_offer: 'Top offer',
      email_reaction_to: 'Reaction to',
      email_body: 'Please, dont forget to attach CV'
    }
  },
  event: {
    title: 'Upcoming events',
    subtitles: {
      online: 'Online',
      slovakia_czech: 'Slovakia and the Czech Republic',
      event_info: 'Event info',
      organizer: 'Organizer'
    },
    descriptions: {
      upload_cover_image: 'Upload a cover image',
      basic_information: 'Basic information about the event such as name, place, description and time of the event.',
      organizer_information: 'Stručné informácie o organizátorovy podujatia',
      no_scheduled_events: 'No events are currently scheduled.'
    },
    detail: {
      action: 'Add to calendar'
    },
    inputs: {
      event_link: 'Event link',
      list: 'Description',
      ticket_purchase_link_optional: 'Ticket Purchase Link (Optional)',
      webpage: 'Webpage',
      description_of_organizer: 'Description of the organizer',
      date_and_time: 'Date and Time'
    },
    cover_image: 'Cover image',
    date_and_time: 'Date and Time',
    online_event: 'Online event',
    organize_question: 'Do you organize an event?'
  },
  blog: {
    title: 'Koderia Blog',
    subtitles: {
      more_articles: 'More articles'
    },
    description: 'We write about topics that resonate with the IT world',
    actions: {
      read: 'Read',
      more_articles: 'More articles'
    },
    detail: {
      subtitle: 'You might be interested'
    }
  },
  newsfeed: {
    title: 'Newsfeed',
    action: 'More articles'
  },
  questionaire: {
    title: 'Adequate salary',
    meta_thank_you: 'Thank you - Adequate salary',
    subtitles: {
      experience: 'How experience is appreciated,<br class="tw-hidden lg:tw-block" /> which have you acquired so far?',
      paid_jobs: 'Adequately paid jobs',
      evaluate_resume: 'Evaluate your resume',
      choose_method: 'Choose an evaluation method',
      about_you: 'Information about you',
      master_technologies: 'Technologies you master',
      total_experience: 'Total number of years of experience',
      master_experience: 'Number of years of experience in',
      leadership_experience: 'Have you ever been a Team Leader or Scrum Master?',
      domain_experience: 'Do you have experience with a specific domain?',
      domain_experience_examples: '(e.g. Banking, Medicine, Telecommunications,…)',
      software_architect_experience: 'Have you worked as a software architect?',
      type_of_tester: 'What type of tester are you?',
      ci_experience: 'Do you have experience with CI technologies Docker, Jenkins, GIT, ...?',
      performance_experience: 'Do you have experience with performance test programming?',
      job_listing_title: 'Job list'
    },
    descriptions: {
      salary_evaluate:
        'We evaluate your salary based on the experience you have gained in your career. The evaluation is done by uploading your CV or filling out the form. Both options are highly accurate.',
      experience_evaluate_by_team: 'Vaše skúsenosti vyhodnotí člen/ka Koderia tímu.',
      experience_evaluate_by_system: 'Our experience will be evaluated by our sophisticated system.',
      experience_evaluate_by_pro_team: 'Your experience will be evaluated by a member of the Koderia PRO team.',
      first_step: 'In the first step, it is important to find out which technologies you control and the number of years of your experience.',
      second_step: 'In the second step, provide additional information about your career so far.',
      third_step: 'Finally, leave us your name and email to which we will send the result of the form.',
      answer_questions: 'Answer the following questions.',
      thank_you: 'Thank you for your interest, we will contact you soon.'
    },
    actions: {
      all_jobs: 'All jobs',
      upload_resume: 'Upload resume'
    },
    inputs: {
      choose_position: 'Choose a position'
    },
    programmer: 'Programmer',
    tester: 'Tester',
    it_specialist: 'IT specialist',
    project_manager: 'Project manager',
    analyst: 'Analyst',
    automated_tester: 'Automated tester',
    manual_tester: 'Manual tester'
  },
  calculator: {
    title: 'Salary calculator',
    subtitles: {
      only_for_slovak: 'Only applicable in Slovak Republic',
      possible_earnings:
        'Do you work for a permanent employment?<br class="tw-hidden lg:tw-block" />Find out how much you would earn at a <b>business</b> or <b>s.r.o.</b>',
      find_out_more: 'Discover more.',
      billing_options: 'Billing options',
      price_md: 'Price per man-day',
      price_md_sup: '(Man-day Rate)',
      recommended_job_offers: 'Job offers for you'
    },
    actions: {
      calculate: 'Calculate',
      find_job: 'Find a job',
      get_hired: 'Get hired'
    },
    descriptions: {
      main:
        'The Salary Calculator tool calculates the amount you should invoice if you work for a trade or S.R.O. from the TPP so that <b>the employer\'s costs do not increase.</b>',
      current_income: 'Your current income and contributions on a permanent basis.',
      more_after_login: 'After logging in we will be happy to show you more for free.',
      more_after_verify: 'After verification you email we will be happy to show you more for free.',
      your_md_rate: 'What is your Man-day rate',
      earn_for_trade: 'How much will you earn as a contractor',
      earn_on_sro: 'How much will you earn in case you have your own company',
      fees_and_taxes: 'What fees and taxes will you pay',
      option_for_you: 'Which option is more for you',
      net_monthly_income: 'This is the amount you should receive when you go to invoice.'
    },
    inputs: {
      gross_monthly_income: 'Enter your gross monthly income (min. 700 €)'
    },
    taxes: 'taxes',
    net_monthly_income: 'Net monthly income',
    months_worked_in_year: 'Months worked in the year',
    super_gross_annual_salary: 'Super-gross annual salary',
    employer_levies: 'Employer levies',
    employee_contributions: 'Employee contributions',
    invoice_for_year: 'Billed per year',
    deductions_together: 'Deductions together',
    income_tax: 'Income tax',
    price_md: 'Price per man-day',
    invoice_md: 'The amount you should invoice for 1 working day.',
    trade_first_year: 'Trade in the first year',
    trade_2nd_and_next_year: 'Trade in the 2nd and next year',
    average_net_monthly_income: 'Average net monthly income',
    taxes_and_levies: 'Taxes and levies',
    health_insurance_contributions: 'Contributions to the health insurance company',
    social_insurance_contributions: 'Payments to the social insurance office',
    together: 'Together',
    billing_options_learn_more: 'Learn more about invoicing options in',
    billing_options_learn_more_link: 'this article.',
    company_sro: 'Company (s.r.o.)',
    company_tax: 'Company tax',
    dividend_tax: 'Dividend tax',
    total_taxes: 'Total taxes',
    overview: 'Overview',
    price_of_labor: 'Price of work',
    super_gross_salary: 'Super-gross salary',
    gross_salary: 'Gross salary',
    gross_salary_after_tax: 'Gross salary after tax',
    net_income: 'Net income',
    average_invoice: 'Average invoice',
    company_profit: 'Company profit',
    gross_tax_base: 'Gross tax base',
    flat_cost: 'Flat costs',
    health_insurance: 'Health insurance',
    social_insurance: 'Social insurance',
    view_detailed_taxes_and_levies: 'Show detailed taxes and levies'
  },
  work_comparison: {
    title: 'Comparison of work type',
    subtitle: 'Find out which of the forms <br class="tw-hidden lg:tw-block" />work is best for you',
    subheadings: {
      accounting: 'Accounting',
      establishment: 'Establishment',
      taxes: 'Taxes',
      non_taxable_part: 'Non-taxable part',
      levies: 'Levies'
    },
    descriptions: {
      main: 'Detailed comparison of permanent employment, <br class="tw-hidden lg:tw-block" />contracts and LLCs.',
      taxes_follows_law:
        'It follows from the wording of the law that the 15% tax rate will be applicable to taxpayers with a turnover of up to EUR 49,790 from 2021.',
      annual_amount_non_taxable_part:
        'The annual amount of the non-taxable part of the taxpayer\'s tax base in 2021 is € 4,511.43 if the tax base is less than or equal to € 19,936.22. From this amount until the tax base is higher than the amount of 37,981.94, the non-taxable part decreases to zero.',
      social_insurance_not_paid:
        'Social insurance is not paid. Health insurance is paid a minimum. In the following years, social security contributions apply if in the previous year it exceeded the income limit of € 6,078 (for 2019), the contributions increase together with the income; pays to the health insurance company even if he does not earn anything (the minimum amount of contributions is € 70.91 per month).',
      manager_one_person:
        'If the manager is one person and does not pay his salary, he does not have to pay social security contributions and pays health insurance as a self-paying person; however, if the manager is also an employee, he must pay contributions to both the health and social insurance company.'
    },
    permanent_employment: 'Permanent employment',
    self_employed_person: 'Self-employed person',
    limited_liability_company: 'Limited Liability Company',
    simple: 'simple',
    double: 'double',
    income_tax: 'income tax',
    dividend_tax: 'daň z dividend',
    other: 'Other',
    holiday: 'holiday',
    sick_leave: 'SL',
    retirement_savings: 'retirement savings',
    low_contributions_low_pension: 'low contributions = low pension',
    benefits: 'Benefits',
    disadvantages: 'Disadvantages',
    regular_income: 'regular income',
    financial_benefits: 'financial and non-financial benefits',
    get_loan: 'easier to get a loan',
    lower_salary: 'lower salary',
    working_hours: 'precisely defined working hours',
    notice_period: 'notice period',
    fast_establishment_process: 'fast and cheap establishment process',
    possible_flat_rate_expenses: 'possibility of using flat-rate expenses',
    social_contributions_exemption: 'exemption from social security contributions during the 1st year',
    unlimited_liability: 'unlimited liability',
    health_social_contributions_amount: 'health and social security contributions depend on the amount of income',
    less_credible_image: 'less credible image (for business partners it may give the impression of a small entrepreneur)',
    limited_liability: 'limited liability',
    credible_image:
      'better and more credible image (gives the impression of a bigger and more serious entrepreneur for business partners and potential employees)',
    equal_income_tax: 'equal income tax regardless of the income earned',
    expensive_founding_process: 'a longer and more expensive founding process, including the need to develop founding documents',
    demanding_administration: 'more demanding and expensive administration (mandatory double bookkeeping), 50-70 euros/month',
    termination_process: 'more complicated termination process',
    graph_net_income: 'Graph comparison of net income',
    company: 'Company',
    trade_after_first_year: 'Trade after the first year',
    temporary_incapacity_for_work: 'Temporary incapacity for work',
    chart_popup_title: 'Gross salary (Invoiced amount)',
    chart_popup_more_info: 'For more accurate data, visit the salary calculator'
  },
  extra: {
    title: 'Extra',
    subtitles: {
      your_idea: 'Tell us what your <br class="tw-hidden lg:tw-block" />work should look like',
      project_information: 'Project Information',
      information_about_you: 'Information about you',
      preferred_technologies: 'Preferred technologies'
    },
    descriptions: {
      no_interested_position:
        'Were you not interested in any position? Fill out a short form and we will search for a project for you according to the selected requirements',
      will_be_in_touch: 'Thank you, we will be in touch soon. In the meantime, you can see what\'s new on the homepage.'
    },
    inputs: {
      date_of_start: 'Date of start',
      phone_number_optional: 'Phone number (Optional)',
      question_or_additional_info: 'Questions or additional info (Optional)'
    },
    junior_less_than_2_years: 'Junior (less than 2 years)',
    medior_2_4_years: 'Medior (2 - 4 years)',
    senior_5_and_more: 'Senior (5 and more)'
  },
  referral: {
    title: 'Koderia Referral',
    subtitles: {
      colleague_planning: 'Is your colleague, friend <br class="tw-hidden lg:tw-block" />or acquaintance planning to change jobs?',
      how_it_works: 'How does it work?',
      forms_of_payment: 'Forms of payment',
      one_time_commission: 'one time commission',
      worked_day: 'for every day worked',
      ready_to_collaborate: 'Ready to collaborate?',
      candidate_information: 'Candidate information',
      information_about_you: 'Information about you',
      you_should_know: 'You should know'
    },
    descriptions: {
      recommend_projects: 'Recommend him one of our projects and in case of a mutual agreement, earn a commission every day.',
      friend_looking:
        'Simply. You let us know about your friend or colleague who you know is looking for a job in IT. We will place him on one of our projects and the commission is yours.',
      fill_short_form: 'Fill out a short form, based on which we will contact your friend.',
      interview_with_you: 'We will arrange an interview where we figure out if the expectations are mutual.',
      concluding_contract: 'Signing a contract with a successful candidate and commission payment.',
      one_time_commission:
        'We pay a one-time commission after the first three months worked. We pay € 5 for each day worked monthly, from the beginning to the end of the project. For projects shorter than 6 months, we pay € 500 or € 5 for each day worked. We also pay a commission if we agree with the candidate on another position.',
      recommendation_touch_soon: 'Thank you for your recommendation, we will be in touch soon. In the meantime, you can see what\'s new on the homepage.',
      recommend_to_position: 'Do you recommend a friend to a specific position?',
      preferred_type_of_provision: 'What type of commission do you prefer? (Optional)',
      change_provision_type: 'You can change the selected prize type at any time.',
      forms_of_reward:
        'The forms of remuneration are: one-time commission in the amount of <b>€1000</b>, which we pay after the first three months of work or <b>€5&nbsp; for each day of work</b>, from the beginning to the end of the project (paid monthly).',
      shorter_projects: 'For projects shorter than 6 months, we pay € 500 or € 5 for each day worked.',
      receive_commission: 'You will also receive a commission if we agree with the candidate on another position.',
      data_deleted_from_db: 'Your data will be deleted from our database.',
      recommended_you:
        'He recommended you for one of our vacancies. If you do not know the person who sent us your information, click on the button below and delete your data.'
    },
    inputs: {
      current_position: 'Current position'
    },
    one_time_1000: '€ 1,000 one time',
    per_day_worked_5: '€ 5 per day worked',
    record_not_found: 'Record not found.'
  },
  koderia_about: {
    title: 'What is Koderia?',
    descriptions: {
      unique_project:
        'Koderia is a unique project where all programmers, IT enthusiasts, students of technical subjects, as well as people who are close to the world of IT will find something to their liking. Find out everything Koderia has to offer below.',
      homepage:
        'The Koderia homepage is a stepping stone to all the features and options we offer. You will find the latest job offers, contributions from global portals, IT events in Slovakia but also online from around the world, and much more.',
      job_section:
        'In the job section you will find the latest job offers. We mediate work in companies that are leaders in their field in Slovakia, the Czech Republic, and Austria. We already have under our wings and support dozens of coders.',
      looking_for_colleague:
        'Are you looking for a colleague, friend, or acquaintance to work in IT? Let us know about it. In case of mutual agreement and cooperation, we will share a commission with you. You can earn € 5 a day or a flat rate of € 1,000.',
      interested_offer:
        'Were you not interested in any job offer? By filling out a short form on our website, you can let us know what position you are interested in and we will contact you if we find such an offer.',
      modern_resume:
        'Create a responsive resume, which you can share or print with one click. Your experience will be displayed in a clear graph and it\'s easy to edit.',
      salary_tool: 'A simple tool that determines your salary based on your experience.',
      trade_to_sro: 'Calculation of the invoiced amount during the transition from full-time employment to contract or Ltd.',
      blog: 'We write about topics that resonate with the IT world and about the ideas with which we identify.'
    }
  },
  profile: {
    title: 'Koderia account',
    subtitles: {
      email_settings: 'Email settings',
      invalid_link: 'Invalid link',
      thank_you: 'Thank you',
      linkedin_import_data: 'Import data from LinkedIn',
      account_settings: 'Account settings',
      newsletter: 'Newsletter',
      basic_settings: 'Basic settings',
      delete_account: 'Are you sure you want to cancel your account?',
      confirm_email_address: 'Confirm email address',
      finish_signup: 'Before you begin, <br class="tw-hidden lg:tw-block" />tell us <br class="tw-hidden lg:tw-block" />something about yourself.'
    },
    descriptions: {
      invalid_link: 'The link is not valid or has expired',
      recommended_salary: 'Your salary should not be less than',
      find_out_recommended_salary:
        '<span>Fill out the <a href="#" @click.prevent="$emit(\'go-to-experience\')" class="tw-text-black">experience</a> section</span><br /><span>and find out how much you should earn.</span>',
      approval_of_contribution:
        'We will inform you about the approval of the contribution. You can then find your added posts in the menu, in the "Added" section.',
      share_original_posts: 'Share with us the original posts that interested you on the Internet.',
      no_posts: 'You have not added any posts yet.',
      linkedin_open_personal_data: 'Open a subpage about your personal data',
      linkedin_use_your_data: 'How LinkedIn uses your data',
      linkedin_download_large_data: '<p class="step-info">Choose the option <strong>Download larger data archive…</strong></p>',
      linkedin_request_archive: '<p class="step-info">Then click on <strong>Request archive</strong></p>',
      linkedin_unzip_file: 'Unzip the file you downloaded and upload the files to us',
      linkedin_provide_file: 'Please note that LinkedIn may not provide you with the file immediately.',
      import_click: 'Then click <strong>Import</strong>.',
      private_resume: 'The biography is set to private. You can change it in',
      token_resume_no_password: 'The biography password is not set. You can set it in',
      private_resume_change: 'settings, Privacy section',
      what_is_experience_chart: 'What does this chart represent and how does it work?',
      fill_in_experience_chart: 'For the correct display of the graph, fill in your experience.',
      resume_link: 'You and the people you share it with will find your resume at this link.',
      resume_language: 'The language in which the resume is displayed.',
      resume_social_contact: 'Showing email, phone and website or social network in CV.',
      resume_availability: 'Choose who you want to make your resume available to.',
      account_password_not_allowed: 'Do not enter the password from your account!',
      resume_password_viewing_only: 'This password is only for viewing your resume and will only be available to people you choose to give it to.',
      digital_experience_graph: 'Displaying the experience graph in the digital version of the resume.',
      positions_based_on_technologies: 'New positions according to the technologies you specified in your account settings.',
      sending_news: 'Everything that is new on our portal. We send continuously - maximum 1x per month.',
      new_blog_articles: 'New articles from our blog - maximum 1x per week.',
      account_cancellation_undone: 'Account cancellation is non-refundable. Are you sure you want to cancel your account?',
      no_posts_saved: 'You have not saved any posts yet.',
      no_events_saved: 'You do not have any events saved yet.',
      no_job_offer_saved: 'You have not saved any job offers yet.',
      confirm_email_address:
        'We have sent you a link to confirm your e-mail to the e-mail address <span class="tw-font-bold">{email}</span>. Check your e-mail box, just to be sure, check your spam folder.',
      basic_information: 'Essential information that every CV needs to have',
      about_me_input: '2-3 short sentences about who you are and what do you specialise in',
      employment: 'Provide information about your employment. Start from the latest or current.',
      stack: 'Pick up to 9 items from stack that are most valuable for you and you have most experience in it.',
      stack_notify: 'We have already picked some technologies for you from the previous section 😎',
      education: 'Provide information about your high school, college or university. Start from the latest.',
      education_diploma_placeholder: 'Master of engineering or something, IDK I didn\'t study...',
      references: 'Add information about projects you have worked on and are already online.',
      references_description_input: 'Write short description of the project, or keep blank.',
      certificates: 'If you have any certificates that you want to show, this is the place.',
      certificates_description_input: 'Write short description if necessary',
      languages: 'Let your future employer know, what languages you speak.',
      technology_input: 'Pick technologies that you were using while employed.',
      employment_description_input: 'Write about 3-7 sentences about your job and what were you responsible for.',
      education_description_input: 'Write about 3-6 sentences about your studies.',
      cv_generation_in_progress: 'Your CV is being generated. Once finished, you will receive CV in your email inbox: <b>{email}</b>'
    },
    actions: {
      fill_position: 'Fill in your position',
      respond_to_offer: 'Respond to the offer',
      move_down: 'Move down',
      move_up: 'Move up',
      delete_photo: 'Delete photo',
      change_photo: 'Change photo',
      upload_photo: 'Upload photo',
      delete_custom_cv: 'Delete CV',
      change_custom_cv: 'Change CV',
      upload_custom_cv: 'Upload CV',
      go_to_events: 'Go to events',
      show_posts: 'Show posts',
      find_job: 'Find a job',
      send_cv_by_email: 'Send by email',
      show_resume: 'Show CV',
      create_koderia_cv: 'Create a new Koderia CV',
      pick_previous_tech: 'Select tech from previous job'
    },
    inputs: {
      dominated_technologies: 'Dominated technologies',
      bio: 'Bio',
      current_email: 'Current e-mail',
      new_email: 'New e-mail',
      new_password: 'New password',
      url_link: 'URL link',
      description: 'Description',
      country: 'Country',
      education_diploma: 'Educational attainment / Diploma',
      school_university: 'School / University',
      faculty: 'Faculty',
      technology_software_activity: 'Technology, software, activity…',
      numbers_of_years_of_experience: 'Number of years of experience',
      language: 'Langauge',
      level: 'Level',
      link: 'URL',
      web_social_network: 'Web / Social Network',
      about_me: 'Short info about you',
      new_jobs: 'New jobs',
      koderia_news: 'Koderia news',
      profession_finish_up: 'What best describes your profession?',
      country_finish_up: 'Where are you from?',
      technologies_finish_up: 'Which technologies are you using?',
      technologies: 'Technologies',
      non_it: 'I am not an IT person',
      source_about_us: 'How did you find out about us?',
      non_it_registration_reason: 'Why did you decide to register'
    },
    posts: 'Posts',
    data_in_cv: 'Data',
    basic_information: 'Basic information',
    education: 'Education',
    school: 'School',
    references: 'References',
    reference: 'Reference',
    technologies: 'Technologies',
    years_of_experience: 'Years of experience',
    certificates: 'Certificates',
    certificate: 'Certificate',
    languages: 'Languages',
    experience_chart: 'Experience chart',
    import_your_data_from: 'Import your data from',
    linkedin: 'LinkedIn',
    link: 'Link',
    language: 'Language',
    app_language: 'Application language',
    contact_information: 'Contact information',
    privacy: 'Privacy',
    graph: 'Graph',
    public: 'Public',
    registered_users: 'Registered users',
    private: 'Private',
    accessible_with_password: 'Accessible with a password',
    job_looking_finish_up: 'Are you currently looking for a job?',
    job_timeframe_finish_up: 'In what timeframe do you plan to look for a job?',
    job_listing_title: 'Job list'
  },
  gdpr: {
    title: 'Privacy Policy',
    default_agree: 'I agree with the processing of personal data according to the current ones',
    personal_data: 'personal data protection policy',
    descriptions: {
      privacy_policy_tooltip:
        'By clicking on the checkbox "I agree to the processing of personal data according to the current principles of personal data protection", you give the Operator consent to the processing of your personal data. The purpose of obtaining your personal data is to provide you, based on your request, with information about the adequate amount of salary for the specific chosen position. You give your consent to the processing of personal data for a period of 3 years and you have the right to revoke it at any time at the e-mail address <u> kontakt@koderia.sk </u>. Withdrawal of consent does not affect the legality of the processing of personal data based on consent before its withdrawal.',
      receive_job_offers: 'I do not agree to the occasional sending of tailored job offers',
      contact_tooltip:
        'By clicking on the check box "I agree to the processing of personal data according to the current principles of personal data protection", you give the Operator consent to the processing of your personal data and to their storage in the internal database. The purpose of processing your personal data is to find a suitable job position. You give your consent to the processing of personal data for 5 years and you have the right to revoke it at any time at our e-mail address <u> kontakt@koderia.sk </u>. Withdrawal of consent does not affect the lawfulness of the processing of personal data based on consent prior to its withdrawal.',
      register_account_tooltip:
        'As part of the registration, by clicking on the checkbox „I agree to the processing of personal data according to the current privacy policy“, you give your consent to the processing of personal data and to their storage for the duration of the existence of the account. You can request the cancellation of the account at any time and withdraw your consent to the processing of personal data at our e-mail address <u> kontakt@koderia.sk </u>. Withdrawal of consent does not affect the legality of the processing of personal data based on consent before its withdrawal. Based on the legal basis of legal basis of legitimate interest, in connection with the creation of an account, we will send you our newsletter with job vacancies and other information that may be of interest to you. If you do not wish to receive the newsletter, you can unsubscribe at any time in the account settings, e-mail settings section.',
      newsletter_personalized_offers: 'I agree to receive personalized job offers and news',
      newsletter_contact_me_tooltip:
        'By clicking on the check box "I agree that Kodeira will contact me in the future and send me interesting news", you give the Operator your consent to the storage of personal data in the internal database for the purpose of sending marketing offers. The purpose of processing and storing your personal data is to send you marketing offers that could be of interest to you. You give your consent to the processing of personal data concerning the collection of the newsletter for a period of 5 years and you have the right to revoke it at any time on our Website via the e-mail address <u> kontakt@koderia.sk </u>. Withdrawal of consent does not affect the lawfulness of the processing of personal data based on consent prior to its withdrawal. Your CV will be deleted after the expiration of the consent period or after the withdrawal of the consent to the processing of personal data.',
      project_interest_agree_to_contact: 'I agree that Koderia will contact me in the future and send me interesting news'
    }
  },
  404: {
    title: 'Page not Found',
    subtitle: 'Are you looking for your new job?',
    action: 'All jobs'
  },
  about: {
    title: 'Team of professionals',
    subtitles: {
      way_to_success: 'Our way to success',
      outsourcing_pros: 'Benefits of outsourcing with Koderia',
      contractors_db: '12+ thousand contractors in our internal database',
      personal_approach: 'Personal approach',
      selection_process: 'Quality selection process',
      contact_us: 'Contact us'
    },
    descriptions: {
      hero_outsourcing: `
        We are proud representatives of the Koderia brand and our effort is to provide you with the best
        developers who exactly meet your needs for your projects. We mediate work for companies that are
        <span class="tw-font-bold ">leaders in their field</span> in Slovakia, the Czech Republic and Austria. We already have dozens of programmers under our auspices.
      `,
      objectify: `
        Our company is also part of the renowned Objectify company, which allows us to combine our flexibility and
        personal approach with the support and background of a software house.
      `,
      years_of_experience: `
        Years of experience<br class="tw-hidden lg:tw-block" />
        in the market
      `,
      satisfied_it: `
        Satisfied IT<br class="tw-hidden lg:tw-block" />
        specialists
      `,
      candidates_count: `
        Monthly increase<br class="tw-hidden lg:tw-block" />
        of candidates in the database
      `,
      satisfied_clients: `
        Satisfied<br class="tw-hidden lg:tw-block" />
        clients
      `,
      delivered_candidates: `
        Successfully delivered<br class="tw-hidden lg:tw-block" />
        mandays
      `,
      rocket: {
        salary_calculator: 'Salary calculator',
        salary_calculator_uses: 'with more than 500 thousands uses',
        turnover: 'Overcoming 2 million turnover',
        resume_formation: 'Establishment of the',
        resume: 'Koderia CV',
        resume_sign_in: 'and the possibility of logging into the portal',
        brand_formation: 'Brand creation',
        salary_calculator_launch: 'Launch of the unrivaled',
        salary_calculator_2: 'Salary calculator',
        adequate_salary_launch: 'Launch of the Coders by Coders portal / Launch of the service',
        adequate_salary: 'Adequate salary'
      },
      contractors_db: `
        And for each programmer, we have skills divided into competence centers, where we can precisely target job offers.
        Our specialists have a wide range of professional skills, including software development, data security, cloud
        technologies and many other IT areas.
      `,
      personal_approach: `
        We treat every contractor / programmer as if they were working in our company. We believe that our team of professional IT
        specialists can provide you with not only high-quality IT services, but also the feeling of working with an exceptional team that is fully
        committed to the success of your projects.
      `,
      selection_process: `
        Each team member has gone through a thorough selection process and has experience working on various projects in various industries.
        We provide professional IT specialists with rich experience and expertise for our clients' projects.
      `,
      feedback: `
        Your feedback is very important to us, as it allows us to improve our services and contribute to
        creating a better community for programmers.
      `,
      count_with_us: 'We are here to help you with information and find solutions that meet your needs.',
      form_sent_thank_you: 'Thank you for your message!'
    },
    inputs: {
      name: 'First and last name *',
      phone: 'Phone number *',
      url: 'Company or Linkedin profile',
      message: 'Message *',
      gdpr: 'I agree to the processing of personal data'
    }
  },
  resume: {
    title: 'CV',
    subtitles: {
      modern_clear_cv: 'A neat structured CV<br class="tw-hidden lg:tw-block" /> for everyone in the IT industry.',
      tailored_cv: 'CV tailored<br/> to your needs',
      unique_graph: 'Experience displayed in a clear graph',
      ready_to_print: 'CV ready for printing and secure sharing',
      experience_salary: 'The amount you should earn based on experience',
      reviews: 'Reviews',
      start_new_cv: 'Start writing your new CV',
      cv_is_secure: 'The CV is secured'
    },
    subheadings: {
      experience_chart: 'Experience chart',
      print_share: 'Both sharing and printing',
      adequate_salary: 'Adequate salary',
      responsive_design: 'Responsive design',
      english_language: 'English language',
      password_protected: 'Password protected',
      it_sector_oriented: 'Oriented to the IT sector',
      linkedin: 'LinkedIn Import',
      easy_edit: 'Easy to edit',
      faq_1: 'Is creating a CV really free?',
      faq_2: 'How can I share my CV?',
      faq_3: 'Is the CV available in other languages?',
      faq_4: 'How do I import data from LinkedIn into my CV?'
    },
    descriptions: {
      create_responsive_resume:
        'Create a responsive resume in a few minutes, which you can <b>edit</b>,<br class="tw-hidden lg:tw-block" /> <b>share</b>, or <b>print</b> any time.',
      tailored_cv:
        'Koderia CV is suitable for all people working in the IT industry, including frontend, backend, and full-stack developers, testers, IT specialists, administrators, or database specialists.',
      understand_experiences:
        'What would it be like to see your experiences and understand them in seconds? A graph that visualizes your achieved experience makes this possible.',
      share_and_print: 'Share your resume with everyone or just the people it\'s intended for. If necessary, print it as perfectly as on the screen.',
      experience_salary: 'A CV will show you the amount you should be earning based on the experience you have listed on your CV.',
      displayed_on_device: 'The CV will be displayed without problems on any device',
      english_switch: 'You can switch your CV to English with one click',
      intended_share: 'Show your resume to everyone or only to the people it\'s intended for',
      unique_design: 'Unique resume design for people looking for jobs in the IT industry',
      linkedin_import: 'You can easily transfer data to your CV from your LinkedIn account',
      edit_freely: 'Edit your CV whenever need and from any device',
      faq:
        'We have written down some answers to questions you might be interested in. If you have more, you can reach us at <a href=\'mailto:info@koderia.sk\' class=\'tw-text-underline\'>info@koderia.sk</a>.',
      faq_1: 'It is. You pay nothing at all to create, share or print your CV. No ads, no hidden fees. You\'re welcome! 😎',
      faq_2:
        'Your CV will be accessible via a pre-created link in the form of <b>koderia.sk/cv/id</b>. You can send this link to anyone.',
      faq_3: 'It is. Just like our website, the CV is available in two languages ​​– English and Slovak.',
      faq_4_1: 'Just follow these steps:',
      faq_4_2: 'Open the subpage about your personal data on LinkedIn - How LinkedIn uses your data',
      faq_4_3: 'Select <b>Download larger data archive</b>.',
      faq_4_4: 'Then click <b>Request Archive</b>.',
      faq_4_5:
        'Unzip the downloaded file and upload the files <b>Languages</b>, <b>Courses</b>, <b>Positions</b>, <b>Profile</b> to your CV and <b>Education</b>. Then click <b>Import</b>. Please note that LinkedIn may not provide you with the file immediately.',
      ratings: 'What do people in IT who have already tried it think about Koderia CV? 🥰',
      start_new_cv: 'We took care to make every CV look perfect. We leave the second step to you.',
      private_access: 'The CV is either private, accessible to registered users or password protected.'
    },
    actions: {
      create_resume: 'Create CV',
      show_sample: 'Show sample CV'
    },
    invitation: 'Invitation'
  },
  newsletter: {
    subtitles: {
      android_ios_projects: 'The latest Android and iOS projects to your inbox',
      personalized_jobs: 'Personalized job offers to your inbox'
    },
    descriptions: {
      send_personalized_projects: 'We will only send you personalized projects that match your focus.',
      no_spam: 'No spam',
      koderia_newsletter: 'In the Koderia Newsletter you will find only those job offers that really interest you. Never spam.'
    }
  },
  promo: {
    title: 'projects for whole teams (35+ people)',
    description:
      'We are creating new teams of <span class="tw-font-bold">programmers</span>, <span class="tw-font-bold">testers</span> and <span class="tw-font-bold" >scrum masters</span> for new projects from the banking sector. Work from home for an established client...'
  },
  meta: {
    auth_sign_in: 'Sign in',
    auth_forgot_password: 'Forgot password',
    auth_create_account: 'Create Account',
    work: 'Work',
    events: 'Events',
    blog: 'Blog',
    newsfeed: 'Newsfeed',
    dictionary: 'Dictionary',
    what_is_koderia: 'What is Koderia?',
    adequate_salary: 'Adequate salary',
    adequate_salary_thank_you: 'Thank you - Adequate salary',
    calculator: 'Wage calculator',
    privacy_policy: 'Personal Data Protection Policy',
    resume: 'Resume',
    about_us: 'About us',
    extra: 'Extra',
    newsletter: 'Newsletter',
    promo: 'New remote projects for entire teams (35+ people)',
    referral: 'Referral',
    referral_consent: 'Consent - Referral',
    unsubscribe: 'Unsubscribe',
    account: 'Account',
    account_saved: 'Saved - Account',
    account_added: 'Added - Account',
    my_resume: 'My resume',
    registration_completion: 'Completion of registration',
    work_types_comparison: 'Comparison of forms of work',
    frame_help_bratislava: 'Help Bratislava in building a new team of IT specialists',
    frame_game_industry: 'Work in the game industry',
    frame_net_teams: 'New jobs for .NET teams',
    404: 'Page not found'
  }
}

export default en
